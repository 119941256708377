import React, { ReactElement } from "react"
import DemoCTA from "../../components/DemoCTA/DemoCTA"
import Discover from "../../components/HomePage/Discover"
import { productsList } from "../../constants/products"
import DefaultLayout from "../../templates/DefaultLayout"
import {
  DownloadSample,
  ProductFeature,
  ProductView,
  WatchDemo,
} from "../../components/Product/ProductComponents"
import Seo from "../../components/seo"

const features = [
  "Upload a claimant's earnings history and compare it to different earnings scenarios;",
  "Automatically calculate the tax, net earnings and superannuation;",
  "See and compare all of your analysis visually. Graphed, annotated, clear and concise;",
  "Australian Bureau of Statistics Average Weekly Earnings data sets for each industry built in. Load and compare at the click of a button;",
  "Patch gaps in claimant earnings history with our estimating feature;",
  "Accurately calculate the loss attributable only to the impairment with our Loss Line feature;",
  "Simulate promotions, absences, and other events in the claimant and comparison earnings histories and projections with our Modifiers feature (with annotations);",
  "Estimate future lost earnings with earnings projections and indexing; and",
  "Export your analysis in a professionally presented (MS Word) Economic Loss Report.",
]
export default function EcoLossAnalyser() {
  return (
    <DefaultLayout>
      <Seo
        title="Economic Loss Analyser"
        description=" If you're doing personal injury work (plaintiff or defendant) this tool will change your life. Finally, the power is in the hands of lawyers to do sophisticated economic loss analysis (and give clear instructions to experts) with no math."
      />
      <div className="flex text-br-primary-blue flex-col">
        <ProductView {...productsList[0]} />
        <WatchDemo video={"https://www.youtube.com/embed/NCE165oo8Yo"} />
        <DownloadSample
          link={"/sample-eco-loss-analysis.docx"}
          text={"Download Sample Economic Loss Report"}
        />
        <DemoCTA />
        <Discover hideTitle />
        <ProductFeature title={"A Game Changer for Economic Loss Claims"}>
          <p>
            If you're doing personal injury work (plaintiff or defendant) this
            tool will change your life.
          </p>
          <p className="my-6">
            Finally, the power is in the hands of lawyers to do sophisticated
            economic loss analysis (and give clear instructions to experts) with
            no math.
          </p>
          <p className="my-6">With the Economic Loss Analyser you can:</p>
          <ul className="list-disc lg:ml-16 ml-8">
            {features.map(feature => (
              <li key={feature} className="mt-6">
                {feature}
              </li>
            ))}
          </ul>
        </ProductFeature>
      </div>
    </DefaultLayout>
  )
}
